import mixpanel from 'mixpanel-browser';
import getConfig from 'next/config';
const { publicRuntimeConfig } = getConfig();

//Full API reference here: https://developer.mixpanel.com/docs/javascript-full-api-reference

//mixpanel project tokens
// let environment = {
//   dev: '25856de2916184aae96f5115ab917259',
//   test: 'd6632f36892a8402dc68927131c283da',
//   sandbox: 'cad0382483f76c20ae9cbc3d6cdcd9c9',
//   prod: '3b29c5a54a1b6fadf59ca662f4139f41',
//   review: '810d2d2fec4697eac30c68a69c994fd0'
// };

//set environment value to record to different mixpanel projects
mixpanel.init(publicRuntimeConfig.MIXPANEL_TOKEN);

let actions = {
  //use to identify a customer by Cove customer id
  identify: id => {
    mixpanel.identify(id);
  },
  //use to alias the mixpanel unique id to the Cove customer id (only call on Cove ID create, then use identify for subsequent)
  //this creates a lookup table for the Mixpanel unique id/device ids to the Cove customer id
  alias: id => {
    mixpanel.alias(id);
  },
  //Super property, tracked on all events
  register: props => {
    mixpanel.register(props);
  },
  //Super property which never can be updated, tracked on all events
  register_once: props => {
    mixpanel.register_once(props);
  },
  //send events to Mixpanel i.e. Mixpanel.track('Button Clicked')
  //events can also have properties i.e. Mixpanel.track('Button Clicked', { 'Value': data})
  //try to use main property name as 'Value'
  track: (name, props) => {
    mixpanel.track(name, props);
  },
  //Time an event by including the time between this call and a later 'track' call for the same event in the properties sent with the event
  time_event: props => {
    mixpanel.time_event(props);
  },
  opt_out_tracking: () => {
    mixpanel.opt_out_tracking();
  },
  opt_in_tracking: () => {
    mixpanel.opt_in_tracking();
  },
  //people properties - use these to set properties on a specific user i.e. DOB, Gender, Name
  people: {
    //sets properties on the specific user profile
    set: props => {
      mixpanel.people.set(props);
    },
    //used to set user property values which will not change
    set_once: props => {
      mixpanel.people.set_once(props);
    },
    //used to record revenue events for the specific user
    track_charge: props => {
      mixpanel.people.track_charge(props);
    },
    //used to increment a user property value i.e. Number of quotes. Can use negatives to decrement.
    increment: props => {
      mixpanel.people.increment(props);
    },
    //delete any Cove users playing in PROD
    delete_user: () => {
      mixpanel.people.delete_user();
    }
  }
};

export let Mixpanel = actions;
