// this will add breadcrumbs
import * as logLevel from 'loglevel';
import * as Sentry from '@sentry/browser';

const log = (category, message, data) => {
  logLevel.info(`${category}: ${message}`, data ? data : '');

  Sentry.addBreadcrumb({
    category,
    message,
    data,
    level: Sentry.Severity.Info
  });
};

const error = (category, message, data = null) => {
  logLevel.error(`${category}: ${message}`, data ? data : '');

  Sentry.addBreadcrumb({
    category,
    message,
    data,
    level: Sentry.Severity.Error
  });
};

const warn = (category, message, data = null) => {
  logLevel.warn(`${category}: ${message}`, data ? data : '');

  Sentry.addBreadcrumb({
    category,
    data,
    message,
    level: Sentry.Severity.Warning
  });
};

const info = log;

export default {
  log,
  info,
  error,
  warn
};
